.home-nft .slick-slide {
  transition: linear 100ms;
}

.home-nft .slick-slide:hover {
  opacity: 1;
  transform: scale(1.15);
}

.home-nft .slick-slide {
  padding-right: 1px;
  padding: 20px;
  opacity: 0.95;
}

.home-nft .slick-list {
  padding: 100px 0;
  margin: -100px 0;
}

.home-nft .slick-slide>div {
  margin: 0 -2px;
}

.home-nft .slick-list {
  margin: 0 -10px;
}

.home-nft .slick-prev {
  transform: scale(2);
}

.home-nft .slick-next {
  transform: scale(2);
}

.home-nft .slick-prev:hover,
.home-nft .slick-next:hover {
  /* -webkit-transform: scale(1.1);
  transform: scale(1.1);
  top: 47%; */
}

.home-nft .slick-prev:hover:before,
.home-nft .slick-next:hover:before {
  font-size: 20px;
}

.mobile.home-nft .slick-list {
  padding: 0;
}