.slick-prev {
    /* left: -10px; */
    z-index: 1;
}
.slick-next {
    /* right: -10px; */
}
.slick-prev:hover,
.slick-next:hover {
    /* -webkit-transform: scale(1.1);
    transform: scale(1.1);
    top: 47%; */
}
.slick-prev:hover:before, .slick-next:hover:before {
    font-size: 20px;
}
.slick-slide {
    opacity: 0.8;
    padding-right: 17px;
}
.slick-slide.slick-current {

}
.slick-list {
    margin-right: -17px;
}
.slick-track {
    margin-right: 17px;
}
.slick-slide:hover {
    opacity: 1;
}
.section-grid {

}
.section-slider {
    border-radius: 8px;
    margin-top: 32px;
    background-color: rgba(1, 47, 73, 0.5);
    margin-left: 1%;
    margin-right: 1%;
}
@media (min-width: 1416px) {
    .section-grid {
        width: 1400px;
        margin-left: auto;
        margin-right: auto;
    }
}
@media (min-width: 1401px) {
    .section-slider {
        max-width: 1400px;
        margin-left: auto;
        margin-right: auto;
    }
    
}
.section-head {
    width: 100%;
}
.type2.section-slider {
    box-shadow: 0 4px 0 rgb(43 43 43 / 70%);
}
.section-title {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 16px 0;
}
.section-title-text {
    align-items: center;
    display: flex;
    font-size: 24px;
    font-weight: 700;
    justify-content: normal;
    overflow: hidden;
}
.section-title-tag {
    height: 32px;
    position: relative;
    background: linear-gradient(90deg, #0090eb 50%, rgba(255,255,255,0) 50%);
}
.section-title-tag-icon, .section-colortag-icon {
    align-items: center;
    border-radius: 0 4px 0 rgba(43,43,43,.1);
    box-shadow: 0 4px 0 rgba(43, 43, 43, 0.7);
    display: flex;
    justify-content: flex-start;
    min-width: 32px;
    padding: 0 5px;
}
.section-title-tag-icon, .section-title-tag-icon::after,
.section-colortag-icon, .section-colortag-icon::after {
    background-color: #0090eb;
    height: 100%;
    -webkit-transform: skew(-13deg);
    transform: skew(-13deg);
}
.section-title-text h3 {
    font-size: 24px;
    margin: 0 0 0 16px;
    padding-top: 4px;
}
.section-title div {
    vertical-align: baseline;
}
.section-title-btn {
    border: 0;
    font-size: 100%;
    font: inherit;
    margin: 0;
    padding: 0;
    vertical-align: baseline;
    margin-right: 5px;
}
.section-title-btn-link {
    text-decoration: none;
    align-items: center;
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: inline-flex;
    font-family: Noto Sans TC,Montserrat,Roboto,Helvetica,Arial,sans-serif;
    font-weight: 500;
    justify-content: center;
    line-height: 1.5;
    outline: none;
    overflow: hidden;
    padding: 0 16px;
    text-align: center;
    text-overflow: ellipsis;
    vertical-align: middle;
    white-space: nowrap;
    width: auto;
    color: #0090eb;
    font-size: 16px;
}
.section-body {
    position: relative;
}
.section-body.top-line {
    border-top: 1px solid #e5e5e5;
}
.type2 .section-container {
    padding-left: 8px;
}
.type2 .section-head {
    width: calc(100% + 8px);
    margin-left: -8px;
}
.type2 .section-body {
    padding-left: 0px;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
}
.type2 .section-body .slider-container {

}
.type2 .section-body .slider-content {

}
.type2.slider-card, .type3.slider-card {
    background: rgba(238,238,238,.5);
}
.type2 .item-img, .type3 .item-img {
    width: 40%;
    padding: 8px;
    background-color: black;
    border-radius: 8px;
    overflow: hidden;
}
.type2 .vertical-sep, .type3 .vertical-sep {
    width: 2px;
    align-content: center;
    display: inline-flex;
}
.type2 .vertical-sep .dashed, .type3 .vertical-sep .dashed {
    height: calc(100% - 16px);
    border-left: 2px dashed black;
    display: inline-flex;
    width: 0;
    align-self: center;
}
.type2 .info-wrapper, .type3 .info-wrapper {
    align-items: normal;
    background-color: #f2f2f2;
    border-radius: 8px;
    flex-grow: 0;
    justify-content: space-between;
    padding: 12px 16px;
    right: 2px;
    width: calc(100% - 100px);
    z-index: 0;
    color: black;
    display: flex;
    flex-direction: column;
    min-width: fit-content;
}
.type2 .slick-prev:hover,
.type2 .slick-next:hover {
    /* top: 45.5%;
    -webkit-transform: scale(1.1);
    transform: scale(1.1); */
}
.type2 .info-wrapper button, .type3 .info-wrapper button {
    align-items: center;
    border: none;
    cursor: pointer;
    display: inline-flex;
    font-family: Noto Sans TC,Montserrat,Roboto,Helvetica,Arial,sans-serif;
    font-weight: 500;
    justify-content: center;
    line-height: 1.5;
    outline: none;
    overflow: hidden;
    text-align: center;
    text-overflow: ellipsis;
    vertical-align: middle;
    white-space: nowrap;
    width: auto;
    background-color: #d9effc;
    border-radius: 16px;
    color: #0090eb;
    font-size: 14px;
    max-width: 240px;
    min-width: 74px;
    padding: 4px 16px;
    align-self: flex-end;
    -webkit-text-decoration: none;
    text-decoration: none;
}
.type2 .info-wrapper button:hover, .type3 .info-wrapper button:hover {
    -webkit-text-decoration: none;
    text-decoration: none;
    background-color: #e8f5fd;
    color: #269bf0;
}

.type3.section-slider {
    box-shadow: 0 4px 0 rgb(43 43 43 / 70%);
}
.section-theme-window {
    display: flex;
}
.section-left-info {
    width: 50%;
    /* height: 600px; */
    position: relative;
}
.section-let-info-bg {
    vertical-align: baseline;
    border-radius: 8px 0 0 8px;
    height: 100%;
    top: 0;
    left: -1px;
    position: absolute;
    width: 100%;
    z-index: 1;
    background-color: rgb(25, 20, 0);
    background-color: rgb(33, 131, 140);
}
.section-let-info-colortag {
    border: 0;
    font-size: 100%;
    font: inherit;
    margin: 0;
    padding: 0;
    vertical-align: baseline;
    left: -1px;
    position: absolute;
    width: 100%;
    z-index: 2;
    display: flex;
    overflow: hidden;
    top: 16px;
    align-items: center;
    height: 52px;
    padding-bottom: 4px;
}
.section-colortag {
    background: linear-gradient(90deg, #0090eb 50%, rgba(255,255,255,0) 50%);
}
.section-colortag-icon {
    padding: 12px 8px;
    font: inherit;
    border-radius: 0 2px 3px 0;
    box-shadow: 2px 4px 0 rgb(43 43 43 / 10%);
    -webkit-transform: skew(-13deg);
    transform: skew(-13deg);
}
.section-colortag-text {
    color: #fff;
    font-size: 16px;
    text-shadow: 0 2px 1px #2b2b2b;
    margin: 0;
    margin-right: 8px;
}
.section-let-info-title {
    vertical-align: baseline;
    font-size: 24px;
    color: #fff;
    font-size: 36px;
    font-weight: 700;
    left: 24px;
    position: absolute;
    top: 64px;
    width: 80%;
    word-break: break-all;
    z-index: 3;
    margin: 0;
}
.section-let-info-content {
    vertical-align: baseline;
    align-items: flex-end;
    background-color: #084567;
    border-radius: 8px 0 0 8px;
    display: flex;
    height: 100%;
    justify-content: space-between;
    overflow: hidden;
}
.section-let-info-content .section-keyword-list {
    vertical-align: baseline;
    list-style: none;
    align-items: flex-end;
    color: #fff;
    display: flex;
    flex-wrap: wrap;
    justify-content: normal;
    overflow: hidden;
    width: 40%;
    z-index: 1;
    margin: 0 24px 48px;
    padding: 0;
}
.section-left-banner {
    vertical-align: baseline;
    background-color: #2e769e;
    border-top-left-radius: 60px;
    height: 100%;
    /* max-width: 360px; */
    /* min-width: 360px; */
    overflow: hidden;
    z-index: 1;
    max-width: 58%;
    min-width: 58%;
}
.section-banner-img {
    vertical-align: middle;
    max-width: 100%;
    display: inline-block;
    height: 100%;
    object-fit: cover;
    width: 100%;
}
.section-banner-img.first {
    opacity: .9;
}
.section-banner-img:hover {
    opacity: 1;
}
.section-keyword-list .section-keyword-list-item {
    vertical-align: baseline;
    font-size: 14px;
    margin-right: 10px;
    max-width: 100%;
    padding: 0;
    display: list-item;
    width: auto;
}
.section-keyword-list .section-keyword-list-item+li {
    margin-top: 16px;
}
.section-keyword-list-link {
    text-decoration: none;
    align-items: center;
    background: hsla(0,0%,100%,.2);
    border-radius: 100px;
    color: #fff;
    display: flex;
    font-size: 14px;
    justify-content: center;
    padding: 2px 12px;
    width: 100%;
    border: 0;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    line-height: 1.5;
}
.section-keyword-list-text {
    vertical-align: baseline;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
}
.type3 .section-container {
    width: 50%;
    margin-left: 0;
    margin-top: 0;
}
.section-keyword-list-link:hover {
    cursor: pointer;
}

/* search bar */
.search-container {
    position: relative;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-direction: row;
    color: #000000;
    box-sizing: border-box;
    margin: 0 auto;
    max-width: 1920px;
    min-width: 1024px;
    padding: 0 40px;
    -webkit-box-align: center;
    align-items: center;
    height: 96px;
    /* background-color: white; */
    max-width: 1400px;
}
.search-bar-to-my-auction {

}
.search-bar-to-my-auction-link {
    text-decoration: none;
    outline: none;
    color: white;
}
.search-bar {
    display: flex;
    width: 100%;
    -webkit-box-flex: 1;
    flex-grow: 1;
}
.search-bar-container {
    width: 100%;
    /* background: #FFFFFF; */
    margin: 0 auto;
}
.search-bar-content {
    position: relative;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    flex: 1;
}
.search-bar-label {
    position: absolute;
    height: 1px;
    width: 1px;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);
    white-space: nowrap;
    margin: -1px;
}
.search-bar-combobox {
    display: flex;
    color: #000000;
    position: absolute;
    width: 100%;
    -webkit-box-align: center;
    align-items: center;
    font-weight: 500;
    border-radius: 500px;
    height: 48px;
    font-size: 16px;
    line-height: 20px;
    background-color: #EEEEEE;
    right: 0;
    transform: translateY(-50%);
    max-width: 1036px;
    min-width: 180px;
    -webkit-transition: min-width 400ms ease, max-width 400ms ease;
    -moz-transition: min-width 400ms ease, max-width 400ms ease;
    transition: min-width 400ms ease, max-width 400ms ease;
}
.search-bar .spacer.x16 {
    color: #000000;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    height: 1px;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    margin: 0;
    padding: 0;
    width: 16px;
}
.search-bar .icon {
    font-weight: 500;
    line-height: 20px;
    display: flex;
    flex-shrink: 0;
    -ms-grid-row-align: center;
    align-self: center;
}
.search-bar .icon .icon-img {
    flex: none;
    fill: currentColor;
    width: 14px;
    height: 14px;
}
.search-bar .spacer.x8 {
    color: #000000;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    height: 1px;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    margin: 0;
    padding: 0;
    width: 8px;
}
.search-bar .input {
    padding: 0;
    border: 0;
    font: inherit;
    color: inherit;
    letter-spacing: inherit;
    overflow: hidden;
    text-overflow: ellipsis;
    flex: 1;
    background-color: #EEEEEE;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: none;
}
.search-bar-clear-btn {
    border-width: 0;
    cursor: pointer;
    padding: 0;
    margin: 0;
    background: transparent;
    font: inherit;
    color: inherit;
    letter-spacing: inherit;
    
    cursor: pointer;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #6B6B6B;
}
.search-bar+.spacer.x24, .spacer.x24 {
    color: #000000;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    height: 1px;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    margin: 0;
    padding: 0;
    width: 24px;
}
.search-bar-close-container {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: end;
    justify-content: flex-end;
    -webkit-box-flex: 0;
    flex-grow: 0;
}
.search-bar-close-btn {
    border-width: 0;
    cursor: pointer;
    padding: 0;
    margin: 0;
    background: transparent;
    font: inherit;
    color: inherit;
    letter-spacing: inherit;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-direction: row;
    white-space: nowrap;
    cursor: pointer;
    color: white;
}
.search-bar-close-btn.icon-img {
    flex: none;
    fill: currentColor;
    height: 18px;
    width: 18px;
}
